import { TOKEN, USUARIO, PERMISSOES } from '@/common/constants'

const getters = {
  buscarToken: (state) => state.token,
  buscarRotinasPermitidasUsuario: (state) => {
    return state.permissoes.map((permissao) => permissao.rotina.nome)
  },
  usuarioLogado: (state) => !!state.token,
  verificaPermissaoAcesso: (state) => (nomeRotina, operacao) => {
    return state.permissoes.some(
      (permissao) => permissao.rotina.nome === nomeRotina && permissao.operacoes.includes(operacao)
    )
  }
}

const state = () => ({
  token: localStorage.getItem(TOKEN) || '',
  usuario: JSON.parse(localStorage.getItem(USUARIO)) || {},
  permissoes: JSON.parse(localStorage.getItem(PERMISSOES)) || {}
})

const actions = {
  logOut: () => {
    localStorage.clear()
    location.reload()
  }
}

const mutations = {
  setToken(state, token) {
    localStorage.setItem(TOKEN, token)
    state.token = token
  },

  setUsuario(state, usuario) {
    localStorage.setItem(USUARIO, JSON.stringify(usuario))
    state.usuario = usuario
  },

  setPermissoes(state, permissoes) {
    localStorage.setItem(PERMISSOES, JSON.stringify(permissoes))
    state.permissoes = permissoes
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
