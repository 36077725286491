import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import request from '@/common/request'

Vue.use(VueRouter)

const usuarioLogado = async () => {
  if (store.getters.usuarioLogado) {
    try {
      await request.get('/login/valida-token')
      return true
    } catch (error) {
      return false
    }
  }
  return false
}

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/',
    name: 'Aplicacao',
    component: () => import('../Aplicacao.vue'),
    beforeEnter: async (to, from, next) => {
      ;(await usuarioLogado()) ? next() : next('/login')
    },
    children: [
      {
        path: 'home',
        name: 'Home',
        components: {
          default: () => import('../views/Home.vue')
        }
      },
      {
        path: 'usuarios',
        name: 'Usuario',
        component: () => import('../views/usuario/Usuario.vue')
      },
      {
        path: 'perfis',
        name: 'Perfil',
        component: () => import('../views/usuario/Perfil.vue')
      },
      {
        path: 'permissoes',
        name: 'Permissao',
        component: () => import('../views/usuario/Permissao.vue')
      },
      {
        path: 'empresas',
        name: 'Empresa',
        component: () => import('../views/organizacao/Empresa.vue')
      },
      {
        path: 'organizacoes',
        name: 'Organizacao',
        component: () => import('../views/organizacao/Organizacao.vue')
      },
      {
        path: 'integracoes/paypal',
        name: 'IntegracaoPayPal',
        component: () => import('../views/integracao/IntegracaoPayPal.vue')
      },
      {
        path: 'integracoes/pagseguro',
        name: 'IntegracaoPagSeguro',
        component: () => import('../views/integracao/IntegracaoPagSeguro.vue')
      },
      {
        path: 'integracoes/getnet',
        name: 'IntegracaoGetNet',
        component: () => import('../views/integracao/IntegracaoGetNet.vue')
      },
      {
        path: 'integracoes/rede',
        name: 'IntegracaoRede',
        component: () => import('../views/integracao/IntegracaoRede.vue')
      },
      {
        path: 'integracoes/systextil',
        name: 'IntegracaoSystextil',
        component: () => import('../views/integracao/IntegracaoSystextil.vue')
      },
      {
        path: 'titulo',
        name: 'Titulo',
        component: () => import('../views/titulo/Titulo.vue')
      },
      {
        path: 'pagamento',
        name: 'Pagamento',
        component: () => import('../views/pagamento/Pagamento.vue')
      },
      {
        path: 'conciliacao',
        name: 'Conciliacao',
        component: () => import('../views/conciliacao/Conciliacao.vue')
      },
      {
        path: 'pagamentosConciliados',
        name: 'PagamentosConciliados',
        component: () => import('../views/pagamentosConciliados/PagamentosConciliados.vue')
      },
      {
        path: '*',
        redirect: '/home'
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
