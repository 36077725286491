import axios from 'axios'
import { TOKEN } from './constants'

const service = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_API || process.env.baseURL || process.env.apiUrl || '',
  timeout: 60 * 1000
})

service.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(TOKEN) || ''
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }
    return config
  },
  (error) => Promise.reject(error)
)

service.interceptors.response.use(
  (response) => response.data,
  (error) => {
    const res = error.response
    if (res.status === 401 || res.status === 403) {
      //store.dispatch('logOut')
      //return
    }
    return Promise.reject(error)
  }
)

export default service

export { service as AppAxios }
